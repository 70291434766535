

















import { Vue, Component, Watch } from 'vue-property-decorator';
import Page from '@/layouts/Page.vue';
import BackButtonLayout from '@/layouts/management/BackButtonLayout.vue';
import QuestionBankListingTemplate from '@/components/question/listing/QuestionBankListingTemplate.vue';
import { FormStores } from '@/store/forms';
import { SubjectStores } from '@/store/subjects';
import { DropdownItem } from '@/models/dropdown';
import { QuestionBankStores } from '@/store/question_banks';
import { UpdateQuestionBankListForAxios, UpdateQuestionBankListForm } from '@/models/question_banks';
import { uploadAttachment } from '@/utils/attachments';

@Component({
  components: { Page, BackButtonLayout, QuestionBankListingTemplate },
})
export default class AddQuestionBank extends Vue {
  subjectListStore = SubjectStores.list;
  levelListStore = FormStores.list;
  questionBankDetailStore = QuestionBankStores.detail;
  questionBankStateStore = QuestionBankStores.state;
  subjectData: DropdownItem[] = [];
  levelData: DropdownItem[] = [];
  currentFormData: UpdateQuestionBankListForm = {
    id: Number(this.$route.params.id),
    sku: ``,
    name: ``,
    description: ``,
    subjectId: -1,
    formId: -1,
    tuitionId: -1,
    thumbnail: ``,
    price: ``,
    discountPrice: ``,
    availability: false,
    questionType: ``,
  };

  get backRoute() {
    return `/question_banks/listing`;
  }

  get title() {
    return `Edit Current Listing`;
  }

  get subjects() {
    return this.subjectData;
  }

  get levels() {
    return this.levelData;
  }

  mounted() {
    this.questionBankDetailStore.retrieveQuestionBankById(Number(this.$route.params.id));
    this.subjectListStore.retrieveSubjects({});
  }

  getFormDropdown(subjectId: number) {
    this.levelListStore.retrieveForms({ subjectid: subjectId });
  }

  @Watch(`questionBankDetailStore.fetchedQuestionResponse`)
  populateCurrentForm() {
    if (this.questionBankDetailStore.fetchedQuestionResponse) {
      this.currentFormData = {
        ...this.questionBankDetailStore.fetchedQuestionResponse,
        availability: this.questionBankDetailStore.fetchedQuestionResponse.availability === 1 ? true : false,
        thumbnail: this.questionBankDetailStore.fetchedQuestionResponse.image,
        price: this.questionBankDetailStore.fetchedQuestionResponse.price.toString(),
        discountPrice: this.questionBankDetailStore.fetchedQuestionResponse.discountPrice > 0 ? 
          this.questionBankDetailStore.fetchedQuestionResponse.discountPrice.toString() : 
          '',
      }
      this.getFormDropdown(this.currentFormData.subjectId);
    }
  }

  @Watch(`subjectListStore.response`)
  updateSubjectDropdown() {
    if (this.subjectListStore.response) {
      this.subjectData = [];
      this.subjectListStore.response.objects.forEach((item) => {
        this.subjectData.push({
          text: item.subjectName,
          value: item.id.toString(),
          uniqueKey: `${item.id}_`,
        });
      });
    }
  }

  @Watch(`levelListStore.response`)
  updateFormDropdown() {
    if (this.levelListStore.response) {
      this.levelData = [];
      this.levelListStore.response.objects.forEach((item) => {
        this.levelData.push({
          text: item.formName,
          value: item.id.toString(),
          uniqueKey: `${item.id}_`,
        });
      });
    }
  }

  async updateCurrentQuestionBankList(payload: UpdateQuestionBankListForm) {
    const input: Partial<UpdateQuestionBankListForAxios> = {
      id: this.currentFormData.id,
      name: payload.name,
      description: payload.description,
      subjectId: payload.subjectId,
      formId: payload.formId,
      tuitionId: 1,
      discountPrice: payload.discountPrice.length > 0 ? Number(payload.discountPrice) : 0,
      price: Number(payload.price),
      availability: payload.availability ? 1 : 0,
      sku: this.currentFormData.sku,
      questionType: this.currentFormData.questionType,
    };

    if (payload.thumbnail && typeof payload.thumbnail !== 'string') {
      const thumbnailResponse = await uploadAttachment(
        payload.thumbnail,
        payload.name,
        `thumbnail`
      );
      input.image = thumbnailResponse;
    } else {
      input.image = payload.thumbnail;
    }

    this.questionBankDetailStore.updateQuestionBankById(input);
  }

  @Watch(`questionBankDetailStore.response`)
  redirectToQuestionBankListing() {
    if (this.questionBankDetailStore.response) {
      this.questionBankStateStore.updateQuestionBankListingIsModified(true);
      this.$router.push({
        path: '/question_banks/listing'
      });
    }
  }
}
