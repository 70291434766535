






























































































































































import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator';
import Dropdown from '@/components/common/Dropdown.vue';
import Upload from '@/components/common/Upload.vue';
import SplitInput from '@/components/common/SplitInput.vue';
import Editor from '@tinymce/tinymce-vue';
import { DropdownItem } from '@/models/dropdown';
import { AcceptedImageFileType, QuestionTypeDataSelection } from '@/utils/constants';
import { MultiQuestionBankListForm, UpdateQuestionBankListForm } from '@/models/question_banks';
import { UploadAttachmentWithThumbnail } from '@/models/generic';
@Component({
  components: { Dropdown, SplitInput, Upload, Editor },
})
export default class QuestionBankListingTemplate extends Vue {
  @Prop({ default: false })
  isEditMode!: boolean;

  @Prop({ required: true })
  questionBankListSubjectData!: DropdownItem[];

  @Prop({ required: true })
  questionBankListLevelData!: DropdownItem[];

  @Prop()
  previousQuestionBankData!: UpdateQuestionBankListForm;

  @Prop()
  questionBankListingSubjectPrefixId!: number;

  @Prop()
  questionBankListingLevelPrefixId!: number;

  // Dropdown data
  get subjects() {
    return this.questionBankListSubjectData;
  }

  get levels() {
    return this.questionBankListLevelData;
  }

  get imageFileTypes() {
    return AcceptedImageFileType;
  }

  get currentSku() {
    return this.currentSource.sku;
  }

  get questionTypeData() {
    return QuestionTypeDataSelection;
  }

  get listingType() {
    let baseString = `None`;
    if (this.previousQuestionBankData.questionType) {
      const index = this.questionTypeData.findIndex((data) => {
        return data.type === this.previousQuestionBankData.questionType;
      });
      baseString = index > -1 ? this.questionTypeData[index].name : 'None';
    }
    return baseString;
  }

  // Form Body
  currentSource: MultiQuestionBankListForm = {
    id: -1,
    name: ``,
    sku: ``,
    description: ``,
    subjectId: 0,
    formId: 0,
    tuitionId: 0,
    thumbnail: '',
    price: ``,
    discountPrice: ``,
    availability: false,
    questionType: ``,
  };

  // Disabled variables
  isQuestionBankListLevelDropdownDisabled = true;
  isButtonDisabled = true;

  // General Variables
  selectedQuestionBankListName = '';
  selectedIsAvailable = false;
  selectedQuestionBankListSubject = '- Select -';
  selectedQuestionBankListLevel = '- Select -';
  selectedQuestionBankListDescription = '';
  selectedQuestionBankListThumbnail: File | string | null = null;
  selectedQuestionBankListPrice = '';
  selectedQuestionBankListDiscountedPrice = '';
  selectedQuestionBankListType = '';

  // Edit Variables
  previousSelectedQuestionBankListSubject = 'Select';
  previousSelectedQuestionBankListLevel = 'Select';
  previousSelectedQuestionBankListThumbnail = '';

  private get TinyMceAPIKey() {
    return process.env.VUE_APP_TINY_MCE_API_KEY;
  }

  @Watch(`previousQuestionBankData`)
  repopulateForm() {
    if (this.previousQuestionBankData) {
      this.currentSource = {
        id: this.previousQuestionBankData.id,
        name: this.previousQuestionBankData.name,
        sku: this.previousQuestionBankData.sku,
        description: this.previousQuestionBankData.description,
        subjectId: this.previousQuestionBankData.subjectId,
        formId: this.previousQuestionBankData.formId,
        tuitionId: this.previousQuestionBankData.tuitionId,
        thumbnail: this.previousQuestionBankData.thumbnail,
        price: this.previousQuestionBankData.price,
        discountPrice: this.previousQuestionBankData.discountPrice,
        availability: this.previousQuestionBankData.availability,
        questionType: this.previousQuestionBankData.questionType,
      };

      this.selectedQuestionBankListName = this.currentSource.name;
      this.selectedIsAvailable = this.currentSource.availability;
      this.previousSelectedQuestionBankListSubject = this.currentSource.subjectId.toString();
      this.previousSelectedQuestionBankListLevel = this.currentSource.formId.toString();
      this.selectedQuestionBankListDescription = this.currentSource.description;
      this.previousSelectedQuestionBankListThumbnail = this.currentSource.thumbnail as string;
      this.selectedQuestionBankListPrice = this.currentSource.price;
      this.selectedQuestionBankListDiscountedPrice = this.currentSource.discountPrice;
    }
  }

  @Watch(`questionBankListingSubjectPrefixId`)
  autoFillSubjectSelection() {
    if (this.questionBankListingSubjectPrefixId > -1) {
      this.previousSelectedQuestionBankListSubject = this.questionBankListingSubjectPrefixId.toString();
    }
  }

  @Watch(`questionBankListingLevelPrefixId`)
  autoFillLevelSelection() {
    if (this.questionBankListingLevelPrefixId > -1) {
      this.previousSelectedQuestionBankListLevel = this.questionBankListingLevelPrefixId.toString();
    }
  }

  // Update Functions
  updateQuestionBankListName(payload: string) {
    this.selectedQuestionBankListName = payload;
    this.currentSource.name = payload;
    this.validateForm();
  }

  updateIsAvailable(payload: boolean) {
    this.selectedIsAvailable = payload;
    this.currentSource.availability = payload;
    this.validateForm();
  }

  updateQuestionBankListSubjectState(payload: DropdownItem) {
    this.selectedQuestionBankListSubject = payload.text;
    this.currentSource.subjectId = Number(payload.value);
    this.isQuestionBankListLevelDropdownDisabled = false;
  }

  updateQuestionBankListSubject(payload: DropdownItem) {
    this.updateQuestionBankListSubjectState(payload);
    this.isQuestionBankListLevelDropdownDisabled = true;
    // Reset Level Dropdown
    if (this.currentSource.subjectId > 0) {
      this.isQuestionBankListLevelDropdownDisabled = false;
      this.selectedQuestionBankListLevel = '';
      this.currentSource.formId = 0;
      this.retrieveFormDropdown();
    }
    this.validateForm();
  }

  updateQuestionBankListLevel(payload: DropdownItem) {
    this.selectedQuestionBankListLevel = payload.text;
    this.currentSource.formId = Number(payload.value);
    this.validateForm();
  }

  updateQuestionBankListDescription(payload: string) {
    this.selectedQuestionBankListDescription = payload;
    this.currentSource.description = payload;
    this.validateForm();
  }

  updateQuestionBankListThumbnail(payload: UploadAttachmentWithThumbnail | string) {
    if (typeof payload === 'string') {
      this.currentSource.thumbnail = payload;
      this.selectedQuestionBankListThumbnail = payload;
    } else {
      this.currentSource.thumbnail = payload.originalFile as File;
      this.selectedQuestionBankListThumbnail = payload.originalFile;
    }
    this.validateForm();
  }

  updateQuestionBankListPrice(payload: string) {
    this.selectedQuestionBankListPrice = payload;
    this.currentSource.price = payload;
    this.validateForm();
  }

  updateQuestionBankListDiscountedPrice(payload: string) {
    this.selectedQuestionBankListDiscountedPrice = payload;
    this.currentSource.discountPrice = payload;
    this.validateForm();
  }

  updateQuestionBankListingType(payload: string) {
    this.selectedQuestionBankListType = payload;
    const items = this.questionTypeData.filter((data) => {
      return data.name === payload;
    });
    this.currentSource.questionType = items[0].type;
    this.validateForm();
  }

  validateForm() {
    this.isButtonDisabled = true;

    if (
      this.currentSource.name.length > 0 &&
      this.currentSource.subjectId > 0 &&
      this.currentSource.formId > 0 &&
      this.currentSource.price.length > 0 &&
      this.currentSource.questionType.length > 0 &&
      this.currentSource.description.length > 0 &&
      Number(this.currentSource.price) >= 0 &&
      ((typeof this.currentSource.thumbnail === 'string' &&
        this.currentSource.thumbnail.length > 0) ||
        this.currentSource.thumbnail)
    ) {
      this.isButtonDisabled = false;
    }

    if (
      this.selectedQuestionBankListDiscountedPrice.length > 0 &&
      this.currentSource.discountPrice.length > 0 &&
      Number(this.currentSource.discountPrice) > Number(this.currentSource.price)
    ) {
      this.isButtonDisabled = true;
    }
  }

  // Submit function
  @Emit(`retrieveForms`)
  retrieveFormDropdown() {
    return this.currentSource.subjectId;
  }

  @Emit(`submit`)
  submitForm() {
    return this.currentSource;
  }
}
